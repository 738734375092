import { FC, memo, useEffect, useState } from 'react'

import { event } from '@headless/utils'

import ProductPrice from '@components/ProductPrice'
import ProductReview from '@components/ProductReview'
import { NoSsr } from '@headless/ui'
import { isBundleProductFunction } from '@headless/utils/tools/isBundleProductFunction'

import CatalogActions from '../CatalogActions'
import CatalogCollapse from '../CatalogCollapse'
import CatalogMedia from '../CatalogMedia'
import CatalogNotify from '../CatalogNotify'
import CatalogShare from '../CatalogShare'
import { StyledCatalogDetail, StyledCatalogMain } from './styled'

interface ProductProps {
  product: any
  onReviewClick: () => void
}

const CatalogMain: FC<ProductProps> = ({ product, onReviewClick }) => {
  const { main_image, media_gallery, price_range, sku, stock_status, name } =
    product || {}
  const [currenct, setCurrenct] = useState<any>({
    image: main_image,
    gallery: media_gallery,
    price: price_range,
    stock: stock_status,
    sku,
    option: {}
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.dataLayer && window.dataLayer.push({ ecommerce: null })
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.dataLayer &&
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_name: name,
              item_id: sku,
              price:
                price_range?.minimum_price?.final_price?.value ||
                price_range?.maximum_price?.final_price?.value,
              quantity: 1
            }
          ]
        }
      })
  }, [
    name,
    price_range?.maximum_price?.final_price?.value,
    price_range?.minimum_price?.final_price?.value,
    sku
  ])

  useEffect(() => {
    setCurrenct({
      image: main_image,
      gallery: media_gallery,
      price: price_range,
      stock: stock_status,
      sku,
      option: {}
    })
  }, [main_image, media_gallery, price_range, sku, stock_status])

  useEffect(() => {
    const handleSelect = (params: any) => {
      setCurrenct({ ...params })
    }

    event.on('updateProductItem', handleSelect)

    return () => {
      event.off('updateProductItem', handleSelect)
    }
  }, [])

  useEffect(() => {
    if (product) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.dataLayer && window.dataLayer.push({ ecommerce: null })
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.dataLayer &&
          window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
              currency: currenct?.price?.minimum_price?.final_price?.currency,
              value: currenct?.price?.minimum_price?.final_price?.value,
              items: [
                {
                  item_name: product.name,
                  item_id: product.uid,
                  price: currenct?.price?.minimum_price?.final_price?.value,
                  quantity: 1
                }
              ]
            }
          })

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.pintrk &&
          window.pintrk('track', 'pagevisit', {
            line_items: [
              {
                product_name: product.name,
                product_id: product.uid,
                product_category: null,
                product_price:
                  currenct?.price?.minimum_price?.final_price?.value,
                product_brand: 'ginni'
              }
            ]
          })

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.fbq &&
          window.fbq('track', 'ViewContent', {
            content_ids: [product.sku],
            content_category: null,
            content_name: product.name,
            content_type: 'product',
            contents: [
              {
                product_name: product.name,
                product_id: product.uid,
                product_category: null,
                product_price:
                  currenct?.price?.minimum_price?.final_price?.value,
                product_brand: 'ginni'
              }
            ],
            currency: currenct?.price?.minimum_price?.final_price?.value,
            value: currenct?.price?.minimum_price?.final_price?.value
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
  }, [product, currenct.price])

  return (
    <StyledCatalogMain>
      <div>
        <NoSsr>
          <CatalogMedia
            image={currenct.image}
            gallery={currenct.gallery}
            videoUrl={product?.video_url}
          />
        </NoSsr>
        <CatalogShare product={product} />
      </div>
      <div className="product__main">
        <h1
          className="product__title"
          dangerouslySetInnerHTML={{ __html: product.name }}
        />
        <StyledCatalogDetail>
          <ProductReview
            count={product.review_count}
            summary={product.rating_summary}
            onReviewClick={onReviewClick}
            showDivider
          />
          <p className="product__sku">
            <strong>Sku:</strong>&nbsp;
            <span>{product.sku}</span>
          </p>
        </StyledCatalogDetail>
        <ProductPrice
          className="product__price"
          {...currenct.price}
          isBundleProduct={isBundleProductFunction(product)}
          product={product}
        />
        <CatalogActions product={product} currenct={currenct} />
        <CatalogCollapse product={product} />
        {currenct.stock === 'OUT_OF_STOCK' && (
          <CatalogNotify id={product.id} sku={product.sku} />
        )}
      </div>
    </StyledCatalogMain>
  )
}

export default memo(CatalogMain)
