import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { isEmpty } from 'lodash'
import Head from 'next/head'
import { FC, memo } from 'react'

import { useCmsPage } from '@hooks/CmsPage'

import CmsBanner from '@components/HomePage/CmsBanner'
import CmsProduct from '@components/HomePage/CmsProduct'
import { LazyScroll } from 'workspaces/ui'
import { StyledCmsPage } from './styled'
import { truncateDescription } from 'workspaces/utils/tools/truncateDescription'

interface CmsPageProps {
  id: number
}

const CmsPage: FC<CmsPageProps> = ({ id }) => {
  const { cmsPage, visible, websiteName } = useCmsPage(id)
  const scriptHtml: string = `<script src="/js/cms.page.js" async />`

  const options: HTMLReactParserOptions = {
    replace: (node: any) => {
      if (node.type === 'script' && typeof window !== 'undefined') {
        const script = document.createElement('script')

        if (node.attribs && node.attribs.src) {
          script.src = node.attribs.src
          script.async = true
        } else {
          script.innerHTML = node.children[0].data
        }

        document.head.appendChild(script)
      }

      if (node.name === 'img') {
        if (node.attribs?.['data-src']) {
          node.attribs.src = node.attribs['data-src']
        }
      }

      if (node.name === 'form') {
        const attribs: any = node?.attribs ?? {}
        delete attribs?.action
        delete attribs?.['data-mage-init']
        node.attribs = { ...attribs, action: '' }
      }

      if (node.name === 'input' || node.name === 'textarea') {
        const attribs: any = node?.attribs ?? {}
        const validate: string = attribs?.['data-validate'] ?? ''

        if (attribs && validate) {
          const result: string[] = attribs['data-validate']
            .replace(/\{/, '')
            .replace(/\}/, '')
            .replace(/'/g, '')
            .replace(/ /g, '')
            .split(',')
          const params: any = {}

          result.forEach((item: any) => {
            const match: string[] = item.split(':')
            const key: string = match[0].replace('validate-', '')
            // eslint-disable-next-line prefer-destructuring
            params[key] = match[1]
          })

          node.attribs = {
            ...attribs,
            ...params
          }
        }
      }
    }
  }

  const maybeCmsBanner = cmsPage?.url_key === 'home' ? <CmsBanner /> : null

  const maybeProducts =
    cmsPage?.url_key === 'home' ? (
      <LazyScroll>
        <CmsProduct />
      </LazyScroll>
    ) : null

  const maybeTitle =
    cmsPage?.url_key !== 'home' ? (
      <h1 className="cms__title">
        <span dangerouslySetInnerHTML={{ __html: cmsPage?.title }} />
      </h1>
    ) : null

  return (
    <>
      {isEmpty(cmsPage) ? null : (
        <>
          <Head>
            {cmsPage.meta_title && (
              <meta name="title" content={cmsPage.meta_title} />
            )}
            {cmsPage.meta_keywords && (
              <meta name="keywords" content={cmsPage.meta_keywords} />
            )}
            {cmsPage.meta_description && (
              <meta
                name="description"
                content={truncateDescription(cmsPage.meta_description)}
              />
            )}
            <title>{`${cmsPage.title} - ${websiteName}`}</title>
          </Head>
          <StyledCmsPage>
            {maybeCmsBanner}
            <div className={`cms__container cms__${cmsPage.identifier}`}>
              {maybeTitle}

              {maybeProducts}
              <div className="cms__content">
                {parse(cmsPage?.content, options)}
              </div>
              {visible && (
                <div className="cms__script">{parse(scriptHtml, options)}</div>
              )}
            </div>
          </StyledCmsPage>
        </>
      )}
    </>
  )
}

export default memo(CmsPage)
