import { isEmpty } from 'lodash'
import Head from 'next/head'
import { FC, memo, useRef } from 'react'

import { Container } from '@headless/ui'
import { useCategoryPage } from '@hooks/CatalogPage'

import Breadcrumbs from '@components/Breadcrumbs'
import useScript from '@hooks/useScript'
import CatalogMain from './CatalogMain'
import CatalogRelated from './CatalogRelated'
import CatalogReviews from './CatalogReviews'
import { StyledCatalog } from './styled'
import { truncateDescription } from 'workspaces/utils/tools/truncateDescription'

interface CatalogPageProps {
  urlKey: string
}

const CatalogPage: FC<CatalogPageProps> = ({ urlKey }) => {
  const url: string = urlKey.split('?')[0]
  const { product, breadcrumbCategoryId, seoRichSnippets } =
    useCategoryPage(url)

  useScript(seoRichSnippets, { type: 'application/ld+json' })

  const ref = useRef<HTMLDivElement>(null)

  const onReviewClick = () => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }

  return isEmpty(product) ? null : (
    <>
      <Head>
        {product.meta_title && (
          <meta name="title" content={product.meta_title} />
        )}
        {product.meta_keyword && (
          <meta name="keywords" content={product.meta_keyword} />
        )}
        {product.meta_description && (
          <meta
            name="description"
            content={truncateDescription(product.meta_description)}
          />
        )}
        <title>{product.meta_title || product.name}</title>
      </Head>
      <StyledCatalog>
        <Breadcrumbs
          categoryId={breadcrumbCategoryId}
          currentProduct={product.name}
        />
        <Container>
          <CatalogMain onReviewClick={onReviewClick} product={product} />
          <CatalogRelated url={url} />
          <CatalogReviews ref={ref} product={product} />
        </Container>
      </StyledCatalog>
    </>
  )
}

export default memo(CatalogPage)
